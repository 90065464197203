import React, { useState } from 'react';
import axios from 'axios';

// Define the structure of the response data
interface GitHubFileResponse {
  content: string;
  sha: string;
}

const FormComponent: React.FC = () => {
  // State variables to hold form data and status
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // GitHub repository details
  const repoOwner = 'othmaneelwahidi';
  const repoName = 'Cordonnerie';
  const filePath = 'Cordonnerie.json';
  const branch = 'main';

  // Get GitHub token from environment variable
  const githubToken = process.env.REACT_APP_GITHUB_TOKEN; // Use token from environment variable

  // Function to handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true during submission

    const newEntry = { fullName, email, phone, description }; // New form entry to add

    try {
      // Fetch the current file content from the GitHub repository
      const currentFileResponse = await axios.get<GitHubFileResponse>(
        `https://api.github.com/repos/${repoOwner}/${repoName}/contents/${filePath}?ref=${branch}`,
        {
          headers: { Authorization: `Bearer ${githubToken}` }, // Authorization using token from .env
        }
      );

      // Decode the Base64 content and parse it as JSON
      const currentFileContent = atob(currentFileResponse.data.content);
      const parsedContent = JSON.parse(currentFileContent);

      // Add the new entry to the parsed content
      const updatedContent = Array.isArray(parsedContent)
        ? [...parsedContent, newEntry] // If content is an array, append the new entry
        : [newEntry]; // If content is not an array, create a new array with the entry

      // Encode the updated content back to Base64
      const updatedContentBase64 = btoa(JSON.stringify(updatedContent));

      // Update the file in GitHub with the new content
      await axios.put(
        `https://api.github.com/repos/${repoOwner}/${repoName}/contents/${filePath}`,
        {
          message: 'Update form submission', // Commit message
          content: updatedContentBase64, // Updated content in Base64
          branch: branch, // GitHub branch to update
          sha: currentFileResponse.data.sha, // SHA from the current file to ensure we are updating the correct file
        },
        {
          headers: { Authorization: `Bearer ${githubToken}` }, // Authorization header with the GitHub token
        }
      );

      // On success, show a success message and reset the form fields
      setMessage('Form submitted successfully!');
      setFullName('');
      setEmail('');
      setPhone('');
      setDescription('');
    } catch (error) {
      console.error('Error posting form data to GitHub:', error);
      setMessage('Error submitting form'); // Show error message if the request fails
    } finally {
      setLoading(false); // Reset loading state after the request completes
    }
  };

  return (
    <div>
      <h2 className="H">
        If you have any questions about our products or services, please feel free to reach out by using the form below. We’ll get back to you as soon as possible.
      </h2>
      <div className="form-container">
        <h2>Submit Your Information</h2>
        <form onSubmit={handleSubmit}>
          {/* Full Name Input */}
          <div className="form-group-input">
            <label>Full Name</label>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
          {/* Email Input */}
          <div className="form-group-input">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {/* Phone Number Input */}
          <div className="form-group-input">
            <label>Phone Number</label>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          {/* Description Textarea */}
          <div className="form-group-input">
            <label>Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          {/* Submit Button */}
          <button type="submit" disabled={loading} className="to">
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
        {/* Display submission status message */}
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default FormComponent;
