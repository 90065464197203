import React from 'react';
import '../Style/AdressTel.css';

const AdressTel: React.FC = () => {
  return (
    <div className="adress-container">
      <h1 className="title">Cordonnerie multiservices Villereuse</h1>
      <address className="address">
        Rue Villereuse 1<br />
        1207 Genève
      </address>
      <div className="contact">
        <span>Tel: </span>
        <a href="tel:0227002055" className="phone-link">022 700 20 55</a>
      </div>
      <button className="button-5"><a href="https://www.google.com/maps/place/Cordonnier+villereuse/@46.2002797,6.1561541,17z/data=!3m1!4b1!4m6!3m5!1s0x478c653440b6c2df:0x19c25423df0b79e4!8m2!3d46.200276!4d6.158729!16s%2Fg%2F1tfbgmpr?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D" target='blank' className='a3'>Localisez-nous sur Google Maps</a></button>
    </div>
  );
};

export default AdressTel;
