import React from 'react';


interface DayHours {
  day: string;
  hours: string;
  isClosed?: boolean;
}

const openingHours: DayHours[] = [
  { day: 'Monday', hours: '9:00-19:00' },
  { day: 'Tuesday', hours: '9:00-19:00' },
  { day: 'Wednesday', hours: '9:00-19:00' },
  { day: 'Thursday', hours: '9:00-19:00' },
  { day: 'Friday', hours: '9:00-19:00' },
  { day: 'Saturday', hours: '9:00-18:00' },
  { day: 'Sunday', hours: 'CLOSED', isClosed: true },
];

// Function to get the current day in string format (e.g., 'Monday')
const getCurrentDay = () => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const today = new Date().getDay();
  return days[today];
};

const OpeningHours: React.FC = () => {
  const currentDay = getCurrentDay();

  return (
      <div className='time'>
      <div className='hh'><h2 >Opening hours</h2></div>
      <div className="opening-hours">
        {openingHours.map(({ day, hours, isClosed }) => (
          <div
            key={day}
            className={`day-row ${day === currentDay ? 'highlight' : ''} ${isClosed ? 'closed' : ''}`}
          >
            <span className="day">{day}</span>
            <span className="hours">{hours}</span>
          </div>
        ))}
      </div></div>
  );
};

export default OpeningHours;
