import React, { useState, useEffect } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import ScrollTo from '../Components/ScrollTo';
import Whatsappbutton from '../Components/Whatsappbutton';
import ContactFooter from '../Components/ContactFooter';
import Dataput from '../Components/Dataput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import load from '../Assets/load.mp4';


function Contact() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Hide loading screen after 2 seconds
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  if (isLoading) {
    return (
      <div className="loading-screen">
        <video
          src={load}
          autoPlay
          muted
          loop
          className="loading-video"
        />
      </div>
    );
  }
  return (
    <div> <Navbar/>
    <ScrollTo/>
   <div className='fade-in-up'>
    <div className='data'>
      <br /><br />
    <h1 className='serr'>Have a question <span className='Span'>?</span> <FontAwesomeIcon icon={faEnvelopeOpenText} /> </h1>
    <Dataput/></div>
    <h1 className='serr'>Or click on the WhatsApp button to ask us </h1>
<Whatsappbutton/>
<ContactFooter/>
<Footer/></div>
   </div>
  );
}

export default Contact;
