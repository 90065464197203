import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Logo3 from '../Assets/logo3.png';
import fr from '../Assets/fr.png';
import uk from '../Assets/uk.png';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [selectedLang, setSelectedLang] = useState('en');
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  // Scroll to the element after a delay (to ensure page is loaded)
  const scrollToElement = (targetId: string) => {
    const element = document.getElementById(targetId);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 100); // Delay to ensure the page has been rendered
    }
  };

  // Handle the navigation and scrolling after navigating
  const handleNavigationAndScroll = (path: string, targetId: string) => {
    if (location.pathname === path) {
      // If already on the correct page, scroll directly
      scrollToElement(targetId);
    } else {
      // Navigate to the correct page, then scroll
      navigate(path, { state: { scrollTo: targetId } });
    }
    // Close the menu when a link is clicked
    setIsOpen(false); // Close the menu
  };

  // Handle scroll when coming from the navigation state
  useEffect(() => {
    // If there is a scroll target in the state, scroll to that section
    if (location.state && location.state.scrollTo) {
      const targetId = location.state.scrollTo;
      scrollToElement(targetId);
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-logo">
        <Link to="/">
          <img src={Logo3} alt="Logo" className="round-image" />
        </Link>
      </div>
      <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <div className="custom-dropdown">
          <button className="dropdown-button">
            <img
              src={selectedLang === 'fr' ? fr : uk}
              alt={selectedLang === 'fr' ? 'French flag' : 'UK flag'}
              className="flag-icon"
            />
            {selectedLang === 'fr' ? 'French' : 'English'}
          </button>
          <div className="options">
            <Link to="/en/Home">
              <div onClick={() => setSelectedLang('en')}>
                <img src={uk} alt="UK flag" className="flag-icon" />
              </div>
            </Link>
            <Link to="/fr/Acceuil">
              <div onClick={() => setSelectedLang('fr')}>
                <img src={fr} alt="French flag" className="flag-icon" />
              </div>
            </Link>
          </div>
        </div>
        <li><a href="/en/Home">Home</a></li>
        <li>
          <a
            href="#about"
            onClick={(e) => {
              e.preventDefault();
              handleNavigationAndScroll('/en/Home', 'about');
            }}
          >
            About
          </a>
        </li>
        <li>
          <a
            href="#service"
            onClick={(e) => {
              e.preventDefault();
              handleNavigationAndScroll('/en/Home', 'service');
            }}
          >
            Services
          </a>
        </li>
        <li>
          <Link to="/en/Contact">Contact</Link>
        </li>
      </ul>
      <div className="navbar-toggle" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
