import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';


const ContactFooter: React.FC = () => {


  return (
  
      <div className="foo">
        <footer className="foote">
          <div className="email-message">
         <strong>You can send us your email here for more information</strong>   
          </div>
          <a href="mailto:amalcancerass@gmail.com" className="email-link">
            <FontAwesomeIcon icon={faGoogle} className="email-icon" />
            <span className="red">Villereusecordonnerie@gmail.com</span>
          </a>
        </footer>
      </div>
   
  );
};

export default ContactFooter;
