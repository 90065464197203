import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
const ScrollToo: React.FC = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button onClick={handleScrollToTop} className="scroll-to-top-btn">
      <FontAwesomeIcon icon={faArrowUp} />
    </button>
  );
};

export default ScrollToo;
