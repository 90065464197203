import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { faStamp } from "@fortawesome/free-solid-svg-icons"; 
import { faBatteryFull } from "@fortawesome/free-solid-svg-icons"; // Use the solid version
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons';
import key2 from '../Assets/key2.png';
import gravure from '../Assets/gravure.jpeg';
import stamp from '../Assets/stamp.png';
import images from '../Assets/sphire.jpeg';
import watchImage from '../Assets/watch.png';
import shoes from '../Assets/shoes.png';
const Paragraph: React.FC = () => {
  return (
    <div className="paragraphe">
     
     
      <div className='bord'></div>
      <ul className='shoe'> <li ><div id='cord'>Cordonnerie - La réparation de vos chaussures avec expertise  </div>      </li>
   <img src={shoes} alt="" />
   
  
        
        
<br />
        <p className='portee'>Chez Cordonnerie Villereuse,nous prenons soin de vos chaussures, qu'elles soient pour hommes ou pour femmes. Notre expertise couvre :</p>
        <p className='portee'>Réparation de chaussures : talons, semelles, et autres réparation pour redonner vie à vos paires préférées.</p>
<p className='portee'>Couture sur cuir : pour réparer ou renforcer sacs, chaussures, et autres articles en cuir avec un travail minutieux.</p>
<p className='portee'>Changement de fermeture éclair : notamment sur les bottes, pour une solution durable et esthétique.</p>
<p className='portee'>Nous mettons tout notre savoir-faire artisanal à votre service pour prolonger la durée de vie de vos articles préférés.</p>
<p className='portee'>Confiez-nous vos réparations : vos chaussures méritent le meilleur! </p>
<br /><div className='bord'></div>
</ul>  <ul>
<ul><li className='headee'>
         <div className='grave' id='grave'><FontAwesomeIcon icon={faKey} className="icon" /> Service  Clés </div> 
          </li>
<img src={key2} alt="" />

      <div className='dio'><p className='portee' >Une large gamme pour tous vos besoins . Nous proposons la reproduction de clés pour portes, voitures, motos, vélos, et bien plus encore. Quel que soit le type de clé, nous assurons une duplication rapide, précise et fiable. Nos services sont adaptés aux clés standard ainsi qu'aux clés spéciales de toutes marques. </p>
          </div>
        <br />
        <p className='portee' >
        <div className='dio'> Reproduction tous types de clés. clés plates, brevetées, autos, motos, vélos, clés codée, clés à point...
        </div></p>
        <br />
        <div className='bord'></div>
        <li className='heade'>
        <div className='grave'><FontAwesomeIcon icon={faPaintbrush} className="icon" />Service Gravure</div>  
        </li>
        <img src={gravure} alt="" />
       
        <br />
        <p className="port">
        Nous réalisons des plaques de boite aux lettres personnalisées, disponibles dans plusieurs matiéres et coloris pour répondre à vos besoins.
        Nous proposons également la gravure de médailles pour animaux, afin d'assurer que vos compagnons portent des informations claires et élégantes.
        Un service précis et personnalisé, adapté à vos attentes.
        </p>
        <br />
        <div className='bord'></div>
        <li className='heade'>
          <div className='grave'><FontAwesomeIcon icon={faStamp} className="icon" />Service Tampon</div>
        </li>
      <img src={stamp} alt="" />
       
        <br />
        <p className="port">
     Chez Cordonnerie Villereuse, nous proposons un service rapide et de qualité pour la création de tampons personnalisés. Que vous soyez un professionnel ou un particulier,
     nous réalisons des tampons sur mesure pour répondre à vos besoins : tampons d'adresse, tampons pour entreprises ou tampon à usage personnel. Grace à notre expertise,nous vous garantissons des impressions nettes et durables,
     adaptées à une utilissation quotidienne.
      </p>
        <br />
        <div className='bord'></div>
      
        <div className='bord'></div>
        <li className='head'>
        <div className='montre'> <FontAwesomeIcon icon={faBatteryFull} className="icon" />Service Montre</div>
        </li>
        <img src={watchImage} alt="" />
        
        <br />
        <p className="port">
        Nous intervenons sur vos montres pour le Changement de pile, le remplacement de bracelet,et l'ajustement des bracelets métalliques.
        Nous réalisons également le changement de piles pour les clés de voiture, avec une intervention rapide et efficace.
        </p>
      </ul>
 <br />
 <div className='bord'></div><ul className='shoe'>
 <li  className='hea' id="hea">Vente de Produits pour l'entretien de Chaussures et Accessoires</li> </ul>
 <img src={images} alt="" />

 <br />
   
        <p className='porte'> <br /><br />Nous vous proposons une sélection de produits d'entretien pour chaussures, incluant les prestigieuses marques SAPHIR et SAPHIR MEDAILLE D'OR,
          reconnues parmi les meilleures sur le marché.
          Nous proposons également des embauchoirs pour maintenir la forme de vos chaussures, ainsi que des porte-clés pour ajouter une touche pratique et élégante à vos clés. </p>
       <br />
       </ul>
       
     
   
      <br /><br /><br /><br /><br />
    </div>
  );
};

export default Paragraph;
