
import Searchbar from './SearchBar';


function Header() {
  return (
    <div className='header' >
        <Searchbar/>

    </div>
  );
}

export default Header;
