import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsappButton: React.FC = () => {
  // Ensure the phone number is correct and formatted without spaces or the "+" sign
  const phoneNumber = '41779821666';  // Correct E.164 format: country code + number
  const message = '➤ I have a question about the services you provide.\n➤ J\'ai une question concernant les services que vous proposez';
  const encodedMessage = encodeURIComponent(message);

  return (
    <a
      href={`https://wa.me/${phoneNumber}?text=${encodedMessage}`}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <strong>You have questions? Contact us!</strong>
      <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
    </a>
  );
};

export default WhatsappButton;
