import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faStamp, faBatteryFull, faPaintbrush } from "@fortawesome/free-solid-svg-icons";
import key2 from '../Assets/key2.png';
import gravure from '../Assets/gravure.jpeg';
import stamp from '../Assets/stamp.png';
import images from '../Assets/sphire.jpeg';
import watchImage from '../Assets/watch.png';
import shoes from '../Assets/shoes.png';

const Paragraph: React.FC = () => {
  return (
    <div className="paragraphe">
      <div className='bord'></div>
         <ul className='shoe'>
      <li>
          <div id='cord'>Shoe Repair - Expert Care for Your Footwear   </div>
        </li>
      <img src={shoes} alt="Shoes" />
    
       
        <br />
        <p className='portee'>
        At Cordonnerie Villereuse, we take care of your shoes, whether they're for men or women. Our expertise includes:

        </p>
        <p className='portee'>• Shoe repair: heels, soles, and other fixes to give your favorite pairs a new lease on life.
        </p>
        <p className='portee'>• Leather stitching: to repair or reinforce bags, shoes, and other leather items with meticulous craftsmanship.
        </p>
        <p className='portee'>• Zipper replacement: especially on boots, for a durable and aesthetic solution.
        </p>
        <p className='portee'>
We put all our artisanal know-how at your service to extend the life of your favorite items.
</p>
        <p className='portee'>Trust us with your repairs: your shoes deserve the best!</p>
        <br />
        <div className='bord'></div>
      </ul>      <ul>
      <li className='headee'>
          <div className='grave' id='grave'><FontAwesomeIcon icon={faKey} className="icon" /> Key Service</div>
        </li>
      <img src={key2} alt="Key services" />

       
        <div className='dio'>
          <p className='portee'>
            A wide range of solutions for all your needs. We offer key duplication for doors, cars, motorcycles, bicycles, and more.
            Whatever the type of key, we provide fast, precise, and reliable duplication.
            Our services cater to both standard and special keys of all brands.
          </p>
        </div>
        <br />
        <p className='portee'>
          <div className='dio'>
            Duplication of all key types: flat keys, patented keys, car keys, motorcycle keys, bicycle keys, coded keys, pin keys, and more...
          </div>
        </p>
        <br />
        <div className='bord'></div> <li className='heade'>
          <div className='grave'><FontAwesomeIcon icon={faPaintbrush} className="icon" /> Engraving Service</div>
        </li>
        <img src={gravure} alt="Engraving service" />
       
        <br />
        <p className="port">
          We create custom mailbox plaques , available in various materials and colors to meet your needs.
          We also offer engraving for pet tags, ensuring your companions carry clear and stylish information.
          A precise and personalized service tailored to your expectations.
        </p>
        <br />
        <div className='bord'></div>
        <li className='heade'>
          <div className='grave'><FontAwesomeIcon icon={faStamp} className="icon" /> Stamp Service</div>
        </li>
        <img src={stamp} alt="Stamp service" />
       
        <br />
        <p className="port">
        At Cordonnerie Villereuse, we offer a fast and high-quality service for creating personalized stamps. Whether you are a professional or an individual, we create custom stamps to meet your needs: address stamps, business stamps, or personal-use stamps. With our expertise, we guarantee clear and durable impressions, perfect for daily use.
        </p>
        <br />
        <div className='bord'></div>
        <li className='head'>
          <div className='montre'> <FontAwesomeIcon icon={faBatteryFull} className="icon" /> Watch Service</div>
        </li>
        <img src={watchImage} alt="Watch services" />
       
        <br />
        <p className="port">
        We handle watch services, including battery replacement, bracelet replacement, and adjustment of metal bracelets.

We also replace the batteries for car keys, offering a quick and efficient service.
        </p>
      </ul>
      <br />
      <div className='bord'></div>
      <ul className='shoe'>
        <li id="hea"className='hea'>Shoe Care Products and Accessories for Sale
        </li>
      </ul>
      <img src={images} alt="Products for sale" />
    
      <br />
      <p className='porte'><br /><br />
      We offer a selection of shoe care products, including the renowned SAFIRE and SAFIRE MÉDAILLE D'OR brands, recognized as some of the best on the market.
We also provide shoe trees to maintain the shape of your shoes, as well as keychains to add a practical and stylish touch to your keys.</p>
      <br /><br /><br /><br /><br />
    </div>
  );
};

export default Paragraph;
