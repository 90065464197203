import React, { useState, useEffect } from 'react';
import Nav from '../Component/Nav';
import ScrollT from '../Component/ScrollT';
import Header from '../Component/Header';
import AdressTell from '../Component/AdressTell';
import OpeningHour from '../Component/OpeningHour';
import Shopp from '../Component/Shopp';
import Paragraph from '../Component/Paragraph';
import ScrollToo from '../Component/ScrollToo';
import Foot from '../Component/Foot';
import load from '../Assets/load.mp4';
import Testtt from '../Components/Lowes';
import EmbedMap from '../Components/EmbedMap';

const Home: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Hide loading screen after 2 seconds
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  if (isLoading) {
    return (
      <div className="loading-screen">
        <video
          src={load}
          autoPlay
          muted
          loop
          className="loading-video"
        />
      </div>
    );
  }
 
  return (
    <div >
        <Nav/>
        <ScrollT/>
<div  className='fade-in-up'>
<Header/>
</div>
<AdressTell/>
<EmbedMap/>
<div className='cont'>
<OpeningHour/>
<Shopp/>
</div><section id="a-propos">  </section>
<Testtt/><br />
<h1 className='lo'>Bienvenue à la Cordonnerie multiservices Villereuse</h1>
<p className='paragrap'>Cordonnerie Villereuse vous offre un service de qualité dans le domaine de la cordonnerie et des services associés.Nous mettons à votre disposition un savoir-faire reconnu pour tous vos besoins : duplication de clés, gravure, réparation de chaussures, entretien de montres et bien plus encore.</p>
<p className='paragrap'>Notre objectif est de vous offrir un service rapide, soigné et à un excellent rapport qualité/prix. Nous avons à coeur de vous apporter une expérience chaleureuse et professionnelle, en vous garantissant un service personnalisé et de proximité.</p>
<section id="nos-services">  </section><br /><br /><br />
<h1 className='lo'>Nos Services</h1>

   
      <Paragraph/>  
<ScrollToo/>
<Foot/>
    </div>
  );
};

export default Home;
