import Foot from '../Component/Foot';
import ScrollTo from '../Components/ScrollTo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import Nav from '../Component/Nav';
import Whatsappbuttone from '../Components/Whatsappbuttone';
import ContactFoot from '../Component/ContactFoot';
import Formulaire from '../Component/Formulaire';
import React, { useState, useEffect } from 'react';
import load from '../Assets/load.mp4';

function Contacter() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Hide loading screen after 2 seconds
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  if (isLoading) {
    return (
      <div className="loading-screen">
        <video
          src={load}
          autoPlay
          muted
          loop
          className="loading-video"
        />
      </div>
    );
  }
  return (
    <div>
      <Nav />
      <ScrollTo />
      <div className='fade-in-up'>
        <div className='data'>
          <h1 className='serr'><br /><br />
            Une question <span className='Span'>?</span> <FontAwesomeIcon icon={faEnvelopeOpenText} />
          </h1>
          <Formulaire />
        </div>
        <h1 className='serr'>Ou cliquez sur le bouton WhatsApp pour nous poser votre question</h1>
        <Whatsappbuttone/>
        <ContactFoot />
        <Foot />
      </div>
    </div>
  );
}

export default Contacter;
