import React, { useEffect } from 'react';
import '../Style/App.css';
import Appp from './Appp';
import Appt from './Appt';
import CordonnerieTag from '../Components/CordonnerieTag';





const App: React.FC = () => {
  useEffect(() => {
    document.title = "Cordonnerie multiservices Villereuse";
  }, []);

  return (
    <div className="App">
<CordonnerieTag   language="fr" 
  url="https://cordonnerie-multiservices-villereuse.com/" 
  imageUrl="https://cordonnerie-multiservices-villereuse.com/images/your-image.jpg" 
  faviconUrl="https://cordonnerie-multiservices-villereuse.com/favicon.ico"/> 


      <Appp />
     <Appt/>  
    </div>
  );
};

export default App;
  