import React, { useState } from 'react';
import axios from 'axios';

// Define the structure of the GitHub file response
interface GitHubFileResponse {
  sha: string;
  content: string; // Base64 encoded content
}

const Formulaire: React.FC = () => {
  const [nomComplet, setNomComplet] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [description, setDescription] = useState('');
  const [chargement, setChargement] = useState(false);
  const [message, setMessage] = useState('');

  const proprietaireRepo = 'othmaneelwahidi';
  const nomRepo = 'Cordonnerie';
  const cheminFichier = 'Cordonnerie.json';
  const branche = 'main';

  // Get GitHub token from environment variable
  const tokenGitHub = process.env.REACT_APP_GITHUB_TOKEN; // Secure token

  const gererSoumission = async (e: React.FormEvent) => {
    e.preventDefault();
    setChargement(true);

    const nouvelleEntree = { nomComplet, email, telephone, description };

    try {
      const reponseFichierActuel = await axios.get<GitHubFileResponse>(
        `https://api.github.com/repos/${proprietaireRepo}/${nomRepo}/contents/${cheminFichier}?ref=${branche}`,
        {
          headers: { Authorization: `Bearer ${tokenGitHub}` },
        }
      );

      const contenuFichierActuel = atob(reponseFichierActuel.data.content);
      const contenuParse = JSON.parse(contenuFichierActuel);

      const contenuMiseAJour = Array.isArray(contenuParse)
        ? [...contenuParse, nouvelleEntree]
        : [nouvelleEntree];

      const contenuMiseAJourBase64 = btoa(JSON.stringify(contenuMiseAJour));

      await axios.put(
        `https://api.github.com/repos/${proprietaireRepo}/${nomRepo}/contents/${cheminFichier}`,
        {
          message: 'Mise à jour du formulaire',
          content: contenuMiseAJourBase64,
          branch: branche,
          sha: reponseFichierActuel.data.sha,
        },
        {
          headers: { Authorization: `Bearer ${tokenGitHub}` },
        }
      );

      setMessage('Formulaire soumis avec succès !');
      setNomComplet('');
      setEmail('');
      setTelephone('');
      setDescription('');
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire sur GitHub :', error);
      setMessage('Erreur lors de la soumission du formulaire');
    } finally {
      setChargement(false);
    }
  };

  return (
    <div>
      <h2 className="H">
        Si vous avez des questions sur nos produits ou services, n'hésitez pas à nous contacter via le formulaire ci-dessous. Nous vous répondrons dès que possible.
      </h2>
      <div className="form-container">
        <h2>Soumettez vos informations</h2>
        <form onSubmit={gererSoumission}>
          <div className="form-group-input">
            <label>Nom Complet</label>
            <input
              type="text"
              value={nomComplet}
              onChange={(e) => setNomComplet(e.target.value)}
              required
            />
          </div>
          <div className="form-group-input">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group-input">
            <label>Numéro de Téléphone</label>
            <input
              type="tel"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              required
            />
          </div>
          <div className="form-group-input">
            <label>Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={chargement} className="to">
            {chargement ? 'Envoi en cours...' : 'Soumettre'}
          </button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default Formulaire;
