import React from 'react';

interface JourHeures {
  jour: string;
  heures: string;
  estFerme?: boolean;
}

const heuresOuverture: JourHeures[] = [
  { jour: 'Lundi', heures: '9:00-19:00' },
  { jour: 'Mardi', heures: '9:00-19:00' },
  { jour: 'Mercredi', heures: '9:00-19:00' },
  { jour: 'Jeudi', heures: '9:00-19:00' },
  { jour: 'Vendredi', heures: '9:00-19:00' },
  { jour: 'Samedi', heures: '9:00-18:00' },
  { jour: 'Dimanche', heures: 'FERMÉ', estFerme: true },
];

// Fonction pour obtenir le jour actuel en format chaîne (par exemple, 'Lundi')
const obtenirJourActuel = () => {
  const jours = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  const aujourdHui = new Date().getDay();
  return jours[aujourdHui];
};

const HeuresOuverture: React.FC = () => {
  const jourActuel = obtenirJourActuel();

  return (
    <div>
      <div className='hh'><h2>Heures d'ouverture</h2></div>
      <div className="opening-hours">
        {heuresOuverture.map(({ jour, heures, estFerme }) => (
          <div
            key={jour}
            className={`day-row ${jour === jourActuel ? 'highlight' : ''} ${estFerme ? 'closed' : ''}`}
          >
            <span className="day">{jour}</span>
            <span className="hours">{heures}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeuresOuverture;
