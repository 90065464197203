import React, { useState, useEffect } from 'react';
import Navbar from '../Components/Navbar';
import Headerr from '../Components/Headerr';
import EmbedMap from '../Components/EmbedMap';
import AdressTel from '../Components/AdressTel';
import Footer from '../Components/Footer';
import OpenHours from '../Components/OpeningHours';
import Shop from '../Components/Shop';
import Paragraphe from '../Components/Paragraphe';
import ScrollToTop from '../Components/ScrollToTop';
import ScrollTo from '../Components/ScrollTo';
import load from '../Assets/load.mp4';
import Testtt from '../Components/Lowes';

const Home: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Hide loading screen after 2 seconds
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  if (isLoading) {
    return (
      <div className="loading-screen">
        <video
          src={load}
          autoPlay
          muted
          loop
          className="loading-video"
        />
      </div>
    );
  }
  return (
    <div >
        <Navbar/>
        <ScrollTo/>
<div  className='fade-in-up'>
<Headerr/>
</div>
<AdressTel/>
<EmbedMap/>
<div className='cont'>
<OpenHours/>
<Shop/>

</div><div  id="about"></div>
<Testtt/><br />
<h1 className='lo'>Welcome to Villereuse Multi-Service Shoe Repair</h1>
<p className='paragrap'>
With many years of experience, Cordonnerie Villereuse provides high-quality service in the field of shoe repair and related services. We offer recognized expertise for all your needs: key duplication, engraving, shoe repairs, watch maintenance, and much more.
</p>
<p className='paragrap'>

Our goal is to provide you with fast, meticulous service at great value for money. We aim to offer you a warm and professional experience, guaranteeing personalized service and a close relationship with our clients.</p>
<div  id="service"></div><br /><br /><br /><br />
<h1 className='lo'>Our Services</h1>
<Paragraphe/>
<ScrollToTop/>


<Footer/>
    </div>
  );
};

export default Home;
