import Searchbar from './SearchBarr';

function Header() {
  return (
    <div className='header' >
        <Searchbar/>

    </div>
  );
}

export default Header;
