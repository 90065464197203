import React from 'react';
import '../Style/Footer.css';
import logo from '../Assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigationAndScroll = (path: string, targetId: string) => {
    navigate(path, { state: { scrollTo: targetId } });
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <Link to="/en/Home">
            <img src={logo} alt="" />
          </Link>
        </div>

        <div className="footer-links">
          <h3>Liens Rapides</h3>
          <ul>
            <li>
              <a
                href="#a-propos"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigationAndScroll('/fr/Acceuil', 'a-propos');
                }}
              >
                À Propos de Nous
              </a>
            </li>
            <li>
              <a
                href="#nos-services"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigationAndScroll('/fr/Acceuil', 'nos-services');
                }}
              >
                Services
              </a>
            </li>
            <li>
              <Link to="/fr/Contact">Contact</Link>
            </li>
          </ul>
        </div>

        <div className="footer-contact">
          <h3>Contactez-nous</h3>
          <p>Rue Villereuse 1 1207 Genève</p>
          <p>
            Email:{" "}
            <a href="mailto:Villereusecordonnerie@gmail.com" className="a1">
              Villereusecordonnerie@gmail.com
            </a>
          </p>
          <p>
            Tel:{" "}
            <a href="tel:0227002055" className="a1">
              022 700 20 55
            </a>
          </p>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Cordonnerie multiservices Villereuse. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
