import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Define the structure of the GitHub API response
interface GitHubFileResponse {
  content: string; // The content is Base64 encoded
}

const GetData: React.FC = () => {
  const [data, setData] = useState<{ fullName: string; email: string; phone: string; description: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<GitHubFileResponse>(
          'https://api.github.com/repos/othmaneelwahidi/Cordonnerie/contents/Cordonnerie.json'
        );

        // Décoder le contenu en Base64 et parser le JSON
        const decodedContent = atob(response.data.content);
        const parsedData = JSON.parse(decodedContent);

        // Enregistrer les données analysées dans l'état
        setData(parsedData);
      } catch (err) {
        setError('Erreur lors de la récupération des données');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h2>Commentaires des utilisateurs</h2>

      {loading && <p>Chargement...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {data && data.length > 0 ? (
        <div>
          {data.map((entry, index) => (
            <div key={index} style={{ border: '1px solid #ddd', padding: '10px', margin: '10px 0' }}>
              <p><strong>Nom complet :</strong> {entry.fullName}</p> {/* Remplacé par Nom complet */}
              <p><strong>Email :</strong> {entry.email}</p>
              <p><strong>Téléphone :</strong> {entry.phone}</p>
              <p><strong>Description :</strong> {entry.description}</p>
            </div>
          ))}
        </div>
      ) : (
        !loading && <p>Aucune donnée disponible</p>
      )}
    </div>
  );
};

export default GetData;
