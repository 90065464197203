import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const ContactFoot: React.FC = () => {
  return (
    <div className="foo">
      <footer className="foote">
        <div className="email-message">
          <strong>Vous pouvez nous envoyer votre email ici pour plus d'informations</strong>
        </div>
        <a href="mailto:amalcancerass@gmail.com" className="email-link">
          <FontAwesomeIcon icon={faGoogle} className="email-icon" />
          <span className="red">Villereusecordonnerie@gmail.com</span>
        </a>
      </footer>
    </div>
  );
};

export default ContactFoot;
