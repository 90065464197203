import React from 'react';
import shop from '../Assets/shop.jpg';
import master from '../Assets/mastercard.png';
import visa from '../Assets/visa.png';
import american from '../Assets/american.png';
import maestro from '../Assets/maestro.png';
import cash from '../Assets/cash.png';


const Shop: React.FC = () => {
  return (<div>
    <div className="imagee">
     <img src={shop} alt="Our shop" />
    </div>
    <h2 className='hed'>Payment methods</h2>
    <div className='type' >
        <ul>
        <li ><img src={visa} alt="Visa"className='visa'  /></li>
        <li><img src={master} alt="Master card"  className='visa'/></li>
        <li><img src={american} alt="American express" className='visae' /></li>
        <li><img src={maestro} alt="Maestro" className='visaae' /></li>
        <div className='visaa'><li><img src={cash} alt="Cash" className='visaa' /></li></div></ul></div>
    </div>
  );
};

export default Shop;
